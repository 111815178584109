<template>
  <v-app>
    <v-container
      class="fill-height"
      style="background-color: #eee"
      fluid
    >
      <v-row
        justify="center"
      >
        <v-col lg="3">
          <center>
            <v-img
              :src="require('@/assets/img/logo.png')"
              max-width="250"
            />
          </center>
          <v-card
            class="rounded-xl"
            elevation="24"
          >
            <v-card-title class="py-6">
              Reestablecer contraseña
            </v-card-title>
            <v-card-text>
              <v-form v-show="!success">
                <v-password-field
                  v-model="password"
                  label="Contraseña"
                />
                <v-password-field
                  v-model="confirmPassword"
                  label="Confirmar contraseña"
                />
                <v-row>
                  <v-col>
                    <span
                      v-for="(error, i) in errors"
                      :key="error-i"
                      class="error--text"
                    >
                      {{ error }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="my-3">
                  <v-col>
                    <center>
                      <v-btn
                        color="primary"
                        @click="send"
                      >
                        Enviar
                      </v-btn>
                    </center>
                  </v-col>
                </v-row>
              </v-form>
              <p
                v-show="success"
                class="success--text"
              >
                Se ha reestablecido su contraseña, ya puede iniciar sesion
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  export default {
    data () {
      return {
        password: null,
        confirmPassword: null,
        success: false,
        errors: [],
      }
    },
    mounted () {
      this.code = this.$route.query.code
    },
    methods: {
      async send () {
        const payload = {
          code: this.code,
          password: this.password,
          confirmPassword: this.password,
        }
        this.errors.splice(0, this.errors.length)
        try {
          await this.$api.Auth.resetPassword(payload)
          this.success = true
        } catch {
          this.errors.push('El correo ingresado no esta registrado')
        }
      },
    },
  }
</script>
